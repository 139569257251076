$blue: #2B6CA3;
$dark-blue: #0f3375 ;
$darker-blue: #091d42;

$white: white;

$grey: #F3F2F1; //Secondary bg
$light-grey: #fbfaf9; //Bg
$dark-grey: #605E5C;

$black: #323130;

$colors: map-merge(
  $colors,
  (
    "dark-blue":(
        $blue,
        $dark-blue
    ),
  )
);