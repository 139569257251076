@import "./_variables.scss";

@mixin flex-row {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

@mixin flex-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

*.flex-row {
  @include flex-row();
  > *.flex-one {
    flex: 1;
  }
}

*.flex-col {
  @include flex-col();
  > *.flex-one {
    flex: 1;
  }
}

*.flex-start {
  justify-content: flex-start !important;
}

*.flex-end {
  justify-content: flex-end !important;
}

*.flex-space-between {
  justify-content: space-between !important;
}

*.flex-wrap {
  flex-wrap: wrap !important;
}

*.no-margin-right {
  margin-right: 0 !important;
}

*.no-margin-left {
  margin-left: 0 !important;
}

*.card {
  background: white;
  @include ms-depth-16;
  padding: 20px;
  border-radius: 2px;
  word-break: break-word;
  .head {
    display: none;
    border-radius: 2px 2px 0 0;
    background: linear-gradient(to right top, $dark-blue, $blue);
    border-bottom: 2px rgba($dark-grey, 0.7) solid;
    margin: -25px -25px 15px -25px;
    padding: 5px 5px 5px 5px;
    h1 {
      color: $white;
      text-align: center;
      padding: 0;
      font-size: 1.2em;
      margin: 5px 0;
      > i {
        vertical-align: bottom;
      }
    }
  }
  i {
    //   color: $dark-blue!important;
  }
}

*.is-bold {
  font-weight: bold !important;
}

*.is-underline {
  text-decoration: underline !important;
}

*.is-light {
  font-weight: initial !important;
}

*.is-line-through {
  text-decoration: line-through !important;
}

.ms-Link.link-as-input {
  font-size: 14px;
  margin-left: 8px;
}

textarea {
  resize: vertical;
}

.ms-Button-label {
  //   overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-info-label {
  cursor: help;
  &.is-required {
    margin: 5px 0px 0px -5px;
  }
  &.is-not-required {
    margin: 5px 0px 0px 5px;
  }
}

.left-tab {
  margin-left: 15px;
}

.ms-Dialog-main {
  overflow: hidden !important;
}

@media screen and (min-width: 769px), print {
  .column.is-one-sixth,
  .column.is-one-sixth-tablet {
    flex: none;
    width: 16.66%;
  }
}
