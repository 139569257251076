#team-one {
  .ms-DetailsHeader {
    padding-top: 0 !important;
  }
  .ms-DetailsRow-fields {
    cursor: pointer;
  }
  span {
    > i {
      vertical-align: bottom;
    }
  }
}
