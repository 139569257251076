#index {
  .hero {
    background: linear-gradient(to right top,rgba($blue,.9) 20%,rgba($dark-blue,.9));
    .hero-body {
      padding: 1.5rem 1rem !important;
    }
  }
  div.columns {
    div.column {
      display: flex;
      > * {
        width: 100%;
      }
      p {
        text-align: justify;
        line-height: 1.5rem;
      }
      i {
        vertical-align: bottom;
      }
    }
  }

  .graph {
    > div {
      height: 250px;
    }
  }
}
