#members-me {
    .ms-FocusZone {
        @extend .card;
        padding: 10px;
    }
  #members-me-autorizations {
    .ms-ChoiceField-field {
      &::before {
        top: calc(50% - 10px);
      }
      &::after {
        top: calc(50% - 5px);
      }
    }
  }
  td {
    vertical-align: middle;
  }
  .flex-row.flex-space-between.flex-wrap {
    > *:first-child {
      margin: 0 0 10px 0;
    }
    > *:last-child {
      margin: 0 0 10px auto;
    }
  }
  span {
    > i {
      vertical-align: bottom;
    }
  }
}
