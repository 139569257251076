#login {
  // margin-top: 0.75rem;
  min-height: 100vh;
  overflow: hidden;
  > div {
    min-height: 100vh;
    div.column {
      @include until($tablet) {
        min-height: 100vh;
        @include flex-col();
        img {
          max-width: 50%;
        }
      }
      button {
        position: relative;
        .ms-Spinner {
          position: absolute;
          top: 0;
          bottom: 0;
          right: -20px;
        }
      }
    }
  }
}
