#user-all {
  .search-inputs {
    text-align: left;
    > div.column {
      .ms-Dropdown-title {
        > i {
          vertical-align: bottom;
        }
      }
      @include until($tablet) {
        padding: 0 0.75rem;
      }
    }
    > *:last-child {
      @include until($tablet) {
        label {
          display: none;
        }
      }
      > div {
        @include until($tablet) {
          padding: 0.75rem 0 0;
        }
        width: 100%;
        > span {
          width: 100%;
          > button:first-child {
            width: 100%;
          }
        }
      }
    }
  }

  .ms-DetailsHeader {
    padding-top: 0 !important;
  }
  .ms-DetailsRow-fields {
    cursor: pointer;
  }

  .ms-DetailsRow-cell {
    i {
      vertical-align: bottom;
    }
  }
}
